import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useRouter } from "next/router";
import Link from "next/link";
import { Auth, CognitoUser } from "@aws-amplify/auth";
import { getEnvConfig } from "@/config/environments";
import { validate } from "email-validator";

// ui
import { InputWrapper, Alert, Button, Input, PasswordInput, Text, useAuraMessages } from "@canopyinc/aura";
import parseCognitoError, { CognitoError } from "@/api/auth/parseCognitoError";
import { useI18n } from "@/hooks/useI18n";
import { usePlugins } from "@/hooks/usePlugins";
import { completeSignin } from "@/utils/auth";

type SignInFormProps = { onSignIn: (user: CognitoUser) => void; to?: string };

export const SignInForm = ({ onSignIn, to }: SignInFormProps) => {
  const router = useRouter();
  const { formState, handleSubmit, control, setValue } = useForm({
    mode: "onBlur",
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const plugins = usePlugins();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<{} | null>(null);
  const { dictionary: rootDictionary } = useI18n();
  const dictionary = rootDictionary?.auth?.sign_in;

  const config = getEnvConfig();
  // For backwards compatibility. Flexport's is the env name.
  const ssoProvider = config.ssoProvider || config.appEnv.toLowerCase();
  const ssoEnabled = config.ssoProvider || plugins?.auth?.modules?.sso?.enabled;

  const email = router?.query?.["email"]?.toString();
  useEffect(() => {
    if (email) {
      setValue("email", email);
    }
  }, [email]);

  const isNewPassword = Boolean(router?.query?.["set-password-success"]);
  const { addMessage } = useAuraMessages();
  useEffect(() => {
    if (isNewPassword && dictionary?.set_password?.success !== "") {
      addMessage({ content: dictionary?.set_password?.success, color: "success" });
    }
  }, [isNewPassword, dictionary?.set_password?.success]);

  const login = async (values: { email: string; password: string }, to?: string) => {
    setIsLoading(true);
    try {
      const authUser: CognitoUser = await Auth.signIn({ password: values.password, username: values.email });

      onSignIn(authUser);
      setError(null);

      if (authUser.challengeName === "NEW_PASSWORD_REQUIRED") {
        // return here so we don't set auth token
        // and can't use temporary credentials to navigate app
        return router.push({
          query: { email, v: "set-password" },
        });
      } else if (authUser.challengeName === "SOFTWARE_TOKEN_MFA") {
        return router.push({
          query: { v: "2fa-code" },
        });
      }

      await completeSignin();

      router.push(to || "/app/accounts");
    } catch (err) {
      const cleanedErr = parseCognitoError(err as CognitoError);
      setIsLoading(false);
      setError(cleanedErr);
    }
  };

  const submitForm = async (values) => {
    login(values, to);
  };

  const isTemporaryPassword = router?.query?.["referrer"] === "invitation";

  return (
    <div className="w-full">
      <Text as="h2">
        {isTemporaryPassword
          ? dictionary?.first_time_title
          : isNewPassword
          ? dictionary?.set_password?.success_title
          : dictionary?.title}
      </Text>
      <div className="my-4">
        {error ? (
          <div className="my-2">
            <Alert color="danger" showIcon>
              {error?.toString()}
            </Alert>
          </div>
        ) : null}
      </div>
      <form onSubmit={handleSubmit(submitForm)}>
        <div className="sm:flex w-full mb-2">
          <InputWrapper labelText={dictionary?.email?.label ?? ""} htmlFor="email" id="email_wrapper" required>
            <Controller
              name="email"
              control={control}
              rules={{
                required: dictionary?.email?.validation,
                validate: (email) => validate(email) || dictionary?.email?.validation,
              }}
              render={({ field }) => (
                <Input
                  autoComplete="email"
                  {...field}
                  testid="login-email"
                  invalid={Boolean(formState.errors.email)}
                  error={formState?.errors?.email?.message}
                />
              )}
            />
          </InputWrapper>
        </div>
        <div className="sm:flex w-full mb-3">
          <InputWrapper
            labelText={
              (isTemporaryPassword ? dictionary?.password?.first_time_label : dictionary?.password?.label) ?? ""
            }
            htmlFor="password"
            id="password_wrapper"
            required
          >
            <Controller
              name="password"
              control={control}
              rules={{ required: dictionary?.password?.validation }}
              render={({ field }) => (
                <PasswordInput
                  {...field}
                  testid="login-password"
                  autoComplete="password"
                  invalid={Boolean(formState.errors.password)}
                  error={formState?.errors?.password?.message}
                />
              )}
            />
          </InputWrapper>
        </div>
        <div>
          <Button
            testid="login-submit"
            htmlType="submit"
            loading={isLoading}
            variant="primary"
            fullWidth
            classNames={{ button: "my-2" }}
          >
            {dictionary?.actions?.sign_in}
          </Button>
        </div>
        {ssoEnabled ? (
          <div className="my-2">
            <Button
              fullWidth
              testid="sso-submit"
              onClick={async (e) => {
                // Don't submit the form
                e.preventDefault();
                const { origin, pathname } = window.location;
                window.location.assign(
                  `${config.cognitoDomain}/oauth2/authorize?redirect_uri=${encodeURIComponent(
                    `${origin}${pathname}`
                  )}&response_type=code&client_id=${
                    config.cognitoClientId
                  }&identity_provider=${ssoProvider}&scope=phone%20email%20openid%20profile`
                );
              }}
            >
              {dictionary?.actions?.sso_sign_in}
            </Button>
          </div>
        ) : (
          <></>
        )}
        <div data-testid="forgot-password">
          <Link href={"/forgot-password"} legacyBehavior>
            <a>{dictionary?.actions?.forgot_password}</a>
          </Link>
        </div>
      </form>
    </div>
  );
};
