// stdlib
import React from "react";
import { Auth, CognitoUser } from "@aws-amplify/auth";

// ui
import { Button, InputWrapper, Text } from "@canopyinc/aura";
import { MFACodeFormat } from "@/components/MFACodeFormat";

// hooks
import { useRouter } from "next/router";
import { useI18n } from "@/hooks/useI18n";
import { Controller, useForm } from "react-hook-form";

// utils
import { completeSignin } from "@/utils/auth";
import { track } from "@/libs/analytics";

type MFACodeViewProps = {
  user: CognitoUser | undefined | null;
};

type MFACodeForm = {
  mfa_code: string;
};

export const MFACodeView = ({ user }: MFACodeViewProps) => {
  const router = useRouter();

  const { dictionary: rootDictionary } = useI18n();
  const dictionary = rootDictionary?.auth?.mfa;

  const { control, formState, handleSubmit, watch, setError, ...rest } = useForm<MFACodeForm>({
    mode: "all",
    defaultValues: {
      mfa_code: "",
    },
  });

  const submitForm = async (values: MFACodeForm) => {
    if (user == null) {
      return;
    }

    try {
      await Auth.confirmSignIn(user, values.mfa_code, "SOFTWARE_TOKEN_MFA");
      await completeSignin();

      track("MFA Completed");

      router.push("/app/accounts");
    } catch (e) {
      setError("mfa_code", { type: "error", message: dictionary?.fields?.mfa_code?.validation?.error ?? "" });
    }
  };

  return (
    <div className="w-full">
      <div className="flex flex-col gap-2 mb-4">
        <Text as="h2">{dictionary?.title}</Text>
        <Text>{dictionary?.subtitle}</Text>
      </div>
      <form className="flex flex-col gap-2" onSubmit={handleSubmit(submitForm)}>
        <InputWrapper id="mfa_code" htmlFor="mfa_code" labelText={dictionary?.fields?.mfa_code?.label ?? ""}>
          <Controller
            name="mfa_code"
            control={control}
            rules={{
              required: dictionary?.fields?.mfa_code?.validation?.required,
            }}
            render={({ field }) => (
              <MFACodeFormat
                {...field}
                fieldRef={field.ref}
                testid="mfa-code"
                invalid={Boolean(formState.errors.mfa_code)}
                error={formState?.errors?.mfa_code?.message}
              />
            )}
          />
        </InputWrapper>

        <Button
          testid="set-password-submit"
          loading={formState.isSubmitting}
          disabled={!formState.isValid}
          variant="primary"
          fullWidth
          classNames={{ button: "my-2" }}
        >
          {dictionary?.actions?.submit}
        </Button>
      </form>
    </div>
  );
};
